import React from 'react';
import CallToAction from '@/ui/shared/components/Landing/Sections/CallToAction';
import StartActions from '@/dataroom/ui/components/Landing/dealvdr/components/StartActions';
import styles from './readyToLearn.scss';

const ReadyToLearn = () => (
  <CallToAction
    dataTest="readyToLearnSection"
    title="Ready to learn more?"
    description="We guarantee you and your stakeholders will have a great experience with DealVDR."
    actions={ <StartActions dividerClassName={ styles.divider } /> }
  />
);

export default ReadyToLearn;
